import './About.css';

import * as React from 'react';

import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import sendAnalytics from '../shared/APIService';
import { AppContext } from '../shared/AppContext';

export default function About() {
  let context = React.useContext(AppContext);

  React.useEffect(() => {
    document.title = "Gospelflix | Sobre";

    sendAnalytics(document.title, navigator.userAgent, navigator.language, context.deviceId);
  }, []);

  const qAndA = [
    [
      "O que é o Gospelflix?",
      "Gospelflix é um serviço de vídeo dedicado à propagação da Palavra de Deus."
    ],
    [
      "A plataforma pertence a alguma instituição religiosa?",
      "Não. Essa é uma iniciativa de um engenheiro de software cristão buscando servir ao Senhor."
    ],
    [
      "Como os vídeos são escolhidos para a plataforma?",
      "Atualmente eu (criador do site) e minha esposa escolhemos pregações que falam ao nosso coração. Somos presbiterianos, portanto, as pregações seguem a linha reformada."
    ],
    [
      "Os donos dos vídeos perdem algo por ter seu vídeo listado nesta plataforma?",
      "Não. Os vídeos atualmente são apresentados com o próprio player do Youtube, portanto a monetização original do vídeo é respeitada, bem como contagem de visualizações etc."
    ],
    [
      "O Gospelflix hospedará vídeos?",
      "Sim. A fim de fornecer um espaço dedicado aos pastores e igrejas para a pregação da santa palavra do nosso Senhor, teremos um serviço gratuito de armazenamento de vídeos e player próprio. Isto está atualmente em construção."
    ],
    [
      "Os vídeos hoje listados serão automaticamente hospedados na plataforma futuramente?",
      "Não. Os donos dos conteúdos primeiro serão contatados para verificar se há interesse em firmar parceria com o Gospelflix."
    ],
    [
      "Como ter meus vídeos listados na plataforma?",
      "Por favor em contato através do email \"contato@gospelflix.tv\"."
    ],
    [
      "Haverá monetização no futuro?",
      "Sim. A fim de arcar com os custos e remunerar os pastores e igrejas pela produção de conteúdo, futuramente a plataforma veiculará anúncios."
    ],
    [
      "Haverá cobrança de mensalidade no futuro para acessar o serviço?",
      "A ideia da plataforma é garantir que todos possam ouvir a palavra do nosso amado Pai, portanto, não faria sentido cobrar mensalidade de modo que impeça o acesso de quem não pode pagar."
    ],
    [
      "Como remover meu vídeo?",
      "Por favor entre em contato através do email \"contato@gospelflix.tv\"."
    ]
  ];

  const [open, setOpen] = React.useState(Array(qAndA.length).fill(false));

  const handleClick = (idx) => {
		setOpen(
			open.map((openItem, openItemIdx) => {
				if (openItemIdx === idx) {
					return !openItem;
				}
				else {
					return openItem;
				}
			})
		);
  };

  function renderListItems() {
    return qAndA.map((item, idx) => {
      return (
        <div key={"item"+idx}>
          <ListItemButton onClick={() => handleClick(idx)} className="about-question-container">
            <ListItemText primary={item[0]} />
            {open[idx] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[idx]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton className="about-answer-container">
                <ListItemText primary={item[1]} />
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      );
    });
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" mt={6}>
      <Grid item xs={12} sm={12} md={12}>
        <List
          className="about-container"
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
          <ListSubheader component="h2" className="about-header">
            Sobre o Gospelflix
          </ListSubheader>}
        >
          {renderListItems()}
        </List>
      </Grid>
    </Grid>
  );
}