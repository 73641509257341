import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';

import { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import createVideo, { getPlaylistsNames } from '../APIService';
import { AppContext } from '../../shared/AppContext';

export default function AddYoutubeVideo() {
  let context = useContext(AppContext);
  const [addVideoLoading, setAddVideoLoading] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeLinkError, setYoutubeLinkError] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoTitleError, setVideoTitleError] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [playlists, setPlaylists] = useState([]);

  useEffect(() => {
    getPlaylistsNames(context.auth)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();

        if (status !== 200) {
          toast.error("Falha ao carregar nomes de playlists.");
        }
        else {
          setPlaylists(data);
        }
      });
  }, []);

  function addVideo() {
    setYoutubeLinkError("");
    setVideoTitleError("");

    let linkParts = youtubeLink.split("=");
    if (linkParts.length !== 2) {
      return setYoutubeLinkError("Por favor informe um link válido.");
    }

    let videoId = linkParts[1];

    setAddVideoLoading(true);

    createVideo(context.auth, videoId, videoTitle, selectedPlaylist)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();

        if (status !== 201) {
          if (data["errors"].hasOwnProperty("YoutubeVideoId"))
            setYoutubeLinkError("Por favor informe um link válido.");

          if (data["errors"].hasOwnProperty("Title"))
            setVideoTitleError("Por favor informe título válido.");
        }
        else {
          setYoutubeLinkError("");
          setVideoTitleError("");

          setVideoTitle("");
          setYoutubeLink("");
          setSelectedPlaylist([]);

          toast.success("Vídeo adicionado.");
        }
      })
      .finally(() => setAddVideoLoading(false));
  }

  return (
    <Grid item xs={10} sm={10} md={12} ml={2}>
      <Typography variant="h5" component="h2" mb={1} color="black">Adicionar vídeo (Youtube link)</Typography>
      <Box component="form" noValidate autoComplete="off">
        <Grid container direction="row" justifyContent="start" alignItems="flex-end" spacing={1}>
          <Grid item>
            <TextField label="Youtube link" variant="outlined" required size="small" style={{"width": "20rem"}} error={youtubeLinkError !== ""} helperText={youtubeLinkError} value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} />
          </Grid>
          <Grid item>
            <TextField label="Título do vídeo" variant="outlined" required size="small" style={{"width": "20rem"}} error={videoTitleError !== ""} helperText={videoTitleError} value={videoTitle} onChange={(e) => setVideoTitle(e.target.value)}/>
          </Grid>
          <Grid item>
            <Autocomplete
              size="small"
              style={{"width": "20rem"}}
              freeSolo
              multiple
              autoSelect
              options={playlists}
              value={selectedPlaylist}
              onChange={(event, newValue) => {
                setSelectedPlaylist(newValue); // Update the state
            }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Playlists"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item>
            <LoadingButton variant="outlined" size="small" loading={addVideoLoading} onClick={() => addVideo()}>Adicionar</LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}