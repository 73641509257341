import { useContext, useEffect, useState } from "react";

import CustomNoRowsOverlay from '../../../shared/CustomNoRowsOverlay';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

export default function Devices({data, loading}) {
  const [uniqueDevices, setUniqueDevices] = useState([]);

  useEffect(() => {
    let uniqueDevicesDict = {};
    data.forEach(d => {
      if (d.deviceId in uniqueDevicesDict) {
        uniqueDevicesDict[d.deviceId].accessCount += 1;
        if (uniqueDevicesDict[d.deviceId].latestAccess < d.createdAt)
          uniqueDevicesDict[d.deviceId].latestAccess = d.createdAt;
      }
      else {
        uniqueDevicesDict[d.deviceId] = {
          "accessCount": 1,
          "userLanguage": d.userLanguage,
          "latestAccess": d.createdAt
        };
      }
    });
    
    let uniqueDevicesList = [];
    for (const [key, value] of Object.entries(uniqueDevicesDict)) {
      uniqueDevicesList.push({
        "id": key,
        "accessCount": value.accessCount,
        "userLanguage": value.userLanguage,
        "latestAccess": value.latestAccess
      });
    }

    uniqueDevicesList.sort(function(a, b) { return b.accessCount - a.accessCount; });

    setUniqueDevices(uniqueDevicesList);
  }, [data]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 350 },
    { field: 'accessCount', headerName: 'Número de acessos', width: 250 },
    { field: 'userLanguage', headerName: 'Lingua', width: 250 },
    { field: 'latestAccess', headerName: 'Último acesso', width: 250 },
  ];

  return (
    <Grid item xs={10} sm={10} md={12} ml={2}>
      <Typography variant="h6" component="h2" mb={1} color="black">Dispositivos únicos</Typography>
      <DataGrid
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={uniqueDevices}
        columns={columns}
        pageSize={5}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </Grid>
  );
}