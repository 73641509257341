import './Home.css';

import LoadingButton from '@mui/lab/LoadingButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { getVideoPlaylists } from './APIService';
import { AppContext } from '../shared/AppContext';
import sendAnalytics from '../shared/APIService';
import HomeLoader from './components/HomeLoader';

function VideoThumbnail({title, videoId}) {
  let internalVideoUrl = "v?id="+videoId;
  let thumbnailUrl = "https://i.ytimg.com/vi/"+videoId+"/maxresdefault.jpg"

  return (
    <div className="home-video-card-container">
      <Link to={internalVideoUrl} className="link-no-decoration">
				<img component="img" alt={title} width="100%" src={thumbnailUrl} />
				<div className="home-video-card-title-container">
					<Typography variant="h6" component="h2" className="home-video-card-title">{title}</Typography>
				</div>
      </Link>
    </div>
  );
}

function renderThumbnails(data) {
	return data.map((el, index) => {
		return (
      <VideoThumbnail key={"thumbnail-"+index} title={el["title"]} videoId={el["youtubeVideoId"]} />
		);
	})
}

function renderPlaylists(refs, data, showArrowControllers, setShowArrowControllers, navigate) {
	function setControllerVisibility(value, index) {
		let aux = Array(showArrowControllers.length).fill(false);
		aux[index] = value;
		setShowArrowControllers(aux);
	}

	function renderLeftController(visibility, ref) {
		return (
			<>
				{visibility && <div className="home-playlist-arrow-left" onClick={() => {ref.scrollBy({left: -window.innerWidth, behavior: "smooth"})}}>
					<ArrowBackIosIcon className="home-playlist-arrow-icon-left"/>
				</div>}
			</>
		);
	}

	function renderRightController(visibility, ref) {
		return (
			<>
				{visibility && <div className="home-playlist-arrow-right" onClick={() => {ref.scrollBy({left: window.innerWidth, behavior: "smooth"})}}>
					<ArrowForwardIosIcon className="home-playlist-arrow-icon-right"/>
				</div>}
			</>
		);
	}

	return data.map((el, index) => {
		return (
			<Grid key={"playlist-"+index} container direction="row" justifyContent="center" alignItems="center" mt={2.5}>
				<Grid container direction="row" alignItems="center" mb={1}>
					<Grid item alignItems="center" md="auto">
						<Typography variant="h6" component="h2" className="home-playlist-title">{el["title"]}</Typography>
					</Grid>
					<Grid item md="auto" ml={1.5}>
						<LoadingButton variant="outlined" size="small" onClick={() => navigate("/login")}>Inscrever</LoadingButton>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={12} className="home-playlist-container"  onMouseOver={() => setControllerVisibility(true, index)} onMouseOut={() => setControllerVisibility(false, index)}>
					{renderLeftController(showArrowControllers[index], refs.current[index])}
					<div ref={el => refs.current[index] = el} className="home-playlist-thumbnails-container">
						{renderThumbnails(el["videos"])}
					</div>
					{renderRightController(showArrowControllers[index], refs.current[index])}
				</Grid>
			</Grid>
		);
	})
}

function Home() {
	let context = useContext(AppContext);
	let navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [playlists, setPlaylists] = useState([]);
	const [showArrowControllers, setShowArrowControllers] = useState([]);
	const refs = useRef([]);

	useEffect(() => {
		document.title = "Gospelflix";

		getVideoPlaylists()
			.then(async promise => {
				let status = promise.status;

        if (status === 200) {
					let data = await promise.json();
					refs.current = Array(data.length).fill(null);
					setShowArrowControllers(Array(data.length).fill(false));
					setPlaylists(data);
				}
			})
			.finally(() => setLoading(false));

			sendAnalytics(document.title, navigator.userAgent, navigator.language, context.deviceId);
	}, []);

  return (
    <Grid container direction="row" mt={1} justifyContent="center" alignItems="center" style={{minHeight: "80vh"}}>
			{loading && <HomeLoader visible={loading}/>}
      {!loading && renderPlaylists(refs, playlists, showArrowControllers, setShowArrowControllers, navigate)}
    </Grid>
  );
}

export default Home;