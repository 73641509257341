import './Login.css';

import { TypeAnimation } from 'react-type-animation';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import registerUser, { loginUser } from './APIService';
import { AppContext } from '../shared/AppContext';
import { User } from './User';
import sendAnalytics from '../shared/APIService';

function registerAuthCookie(user) {
  document.cookie = ["auth", "=", JSON.stringify(user)].join('');
}

function KeepMeSignedIn({value, setValue}) {
  let handleChange = (e) => {
    setValue(e.target.checked);
  }

  return (
    <FormControl variant="standard" className="login-button-checkbox">
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={value} onChange={handleChange} name="stay-signed" />}
          label="Continuar logado"
        />
      </FormGroup>
    </FormControl>
  );
}

function PasswordResetComponent() {
  return (
    <Grid item xs={10} sm={10} md={4.5}>
      <Typography variant="h5" component="h2" mb={1}>
        Recuperar senha
      </Typography>
      <Box
        component="form"
        style={{textAlign: "left"}}
        noValidate
        autoComplete="off"
        mb={.4}
      >
        <TextField label="Email" variant="outlined" required size="small" className="login-textfield" />
      </Box>
      <Button variant="outlined" className="login-button" size="small">Recuperar</Button>
    </Grid>
  );
}

function LoginComponent({setResetPassword, context}) {
  const [loginLoading, setLoginLoading] = useState(false);
  const [staySignedIn, setStaySignedIn] = useState(true);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  function login() {
    setLoginLoading(true);

    loginUser(email, password)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json() 
        if (status !== 200) {
          setError("Email ou senha incorretos.");
        }
        else {
          setError("");
          let user = new User(data);

          // Store user object in a cookie
          if (staySignedIn) {
            registerAuthCookie(user);
          }
    
          context.setAuth(user);

          navigate("/");
        }
      })
      .finally(() => setLoginLoading(false));
  }

  return (
    <Grid item xs={10} sm={10} md={4.5}>
      <Typography variant="h5" component="h2" mb={1.5}>Entre na sua conta</Typography>
      <Box
        component="form"
        style={{textAlign: "left"}}
        noValidate
        autoComplete="off"
      >
        <TextField label="Email" required size="small" className="login-textfield" error={error !== ""} helperText={error} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Senha" required size="small" className="login-textfield" type="password" error={error !== ""} helperText={error} onChange={(e) => setPassword(e.target.value)}/>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center" mt={0.4}>
          <LoadingButton className="login-button" size="small" loading={loginLoading} onClick={() => login()}>Entrar</LoadingButton>
          <Button variant="text" className="login-button-borderless" size="small" onClick={() => setResetPassword(true)} style={{"marginLeft": "0.5rem", "marginRight": "0.5rem"}}>Recuperar senha</Button>
          <KeepMeSignedIn value={staySignedIn} setValue={setStaySignedIn} />
        </Grid>
      </Box>
    </Grid>
  );
}

function RegisterComponent({context}) {
  const [registerLoading, setRegisterLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let navigate = useNavigate();

  function register() {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    setRegisterLoading(true);

    registerUser(name, email, password)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();

        if (status !== 201) {
          if (data["errors"].hasOwnProperty("Name"))
            setNameError("Por favor informe o seu nome.");

          if (data["errors"].hasOwnProperty("Email"))
            setEmailError("Por favor informe um e-mail válido.");

          if (data["errors"].hasOwnProperty("Password"))
            setPasswordError("Por favor informe uma senha com pelo menos 6 dígitos.");
        }
        else {
          setNameError("");
          setEmailError("");
          setPasswordError("");
          context.setAuth(new User(data));

          navigate("/");
        }
      })
      .finally(() => setRegisterLoading(false));
  }

  return (
    <Grid item xs={10} sm={10} md={4.5} className="login-component-container">
      <Typography variant="h5" component="h2" mb={1.5}>Criar conta</Typography>
      <Box
        component="form"
        style={{textAlign: "left"}}
        noValidate
        autoComplete="off"
        mb={.4}
      >
        <TextField label="Nome" required size="small" className="login-textfield" error={nameError !== ""} helperText={nameError} onChange={(e) => setName(e.target.value)} />
        <TextField label="Email" required size="small" className="login-textfield" error={emailError !== ""} helperText={emailError} onChange={(e) => setEmail(e.target.value)} />
        <TextField label="Senha" required size="small" className="login-textfield" type="password" error={passwordError !== ""} helperText={passwordError} onChange={(e) => setPassword(e.target.value)}/>
      </Box>
      <LoadingButton size="small" className="login-button" loading={registerLoading} onClick={() => register()}>Cadastrar</LoadingButton>
    </Grid>
  );
}

function Login() {
  let context = useContext(AppContext);
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    document.title = "Gospelflix | Login";

    sendAnalytics(document.title, navigator.userAgent, navigator.language, context.deviceId);
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" mt={12}>
      <Grid item xs={12} sm={4} md={4}>
        <TypeAnimation
          sequence={[
            "Aqui você encontra alimento para a sua alma.",
            2000,
            "Uma plataforma dedicada à proclamação do Reino de Deus.",
            2000,
            "Dedicados à exposição da Palavra de Deus.",
            2000
          ]}
          wrapper="h2"
          speed={40}
          className="login-left-text-container"
          repeat={Infinity}
          omitDeletionAnimation={true}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Grid container mt={4} direction="row" justifyContent="center" pt={4} pb={5}>
          {resetPassword ? 
          <PasswordResetComponent /> : <LoginComponent setResetPassword={setResetPassword} context={context} />}
          <RegisterComponent context={context} />
        </Grid>
      </Grid>
    </Grid>
  );
}
  
export default Login;