import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CustomNoRowsOverlay from '../../shared/CustomNoRowsOverlay';

import { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import {
  getAllVideos,
  getPlaylistsNames,
  updateVideos as updateVideosAPI,
  deleteVideos as deleteVideosAPI
} from '../APIService';
import { AppContext } from '../../shared/AppContext';
import LoadingButton from '@mui/lab/LoadingButton';

export default function ListYoutubeVideos() {
  let context = useContext(AppContext);
  const [playlists, setPlaylists] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [updateVideosLoading, setUpdateVideosLoading] = useState(false);
  const [deleteVideosLoading, setDeleteVideosLoading] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videosToEdit, setVideosToEdit] = useState([]);
  const apiRef = useGridApiRef();

  function updateVideos() {
    setUpdateVideosLoading(true);
    let payload = [];
    videosToEdit.forEach(e => {
      payload.push(e);
      payload[payload.length - 1]["id"] = payload[payload.length - 1]["dbId"];
    });

    updateVideosAPI(context.auth, videosToEdit)
      .then(async promise => {
        let status = promise.status;
        
        if (status === 200) {
          toast.success("Vídeos atualizados.");
        }
      })
      .finally(() => setUpdateVideosLoading(false));
  }

  function deleteVideos() {
    setDeleteVideosLoading(true);
    deleteVideosAPI(context.auth, selectedVideos)
      .then(async promise => {
        // Remove video from videos list.
        let aux = [];
        videos.forEach(e => {
          if (!selectedVideos.includes(e.id)) { 
            aux.push(e);
          }
        });
        setVideos(aux);

        let status = promise.status;
        
        if (status === 200) {
          toast.success("Vídeos removidos.");
        }
      })
      .finally(() => setDeleteVideosLoading(false));
  }
  
  useEffect(() => {
    setListLoading(true);

    getPlaylistsNames(context.auth)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();

        if (status !== 200) {
          toast.error("Falha ao carregar nomes de playlists.");
        }
        else {
          setPlaylists(data);
        }
      });

    getAllVideos(context.auth)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();
        
        data.forEach(element => {
          element["dbId"] = element["id"];
        });

        if (status !== 200) {
          toast.error("Falha ao carregar lista de vídeos.");
        }
        else {
          setVideos(data);
        }
      })
      .finally(() => setListLoading(false));
  }, []);

  const columns = [
    { field: 'title', headerName: 'Título', width: 350, editable: true },
    { field: 'youtubeVideoId', headerName: 'Vídeo id', width: 140, editable: true },
    {
      field: 'playlists',
      headerName: 'playlists',
      width: 300,
      editable: false,
      renderCell: (params) => (
        <Autocomplete
          size="small"
          style={{"width": "100%"}}
          freeSolo
          multiple
          autoSelect
          options={playlists}
          value={params.row.playlists}
          onChange={(event, newValue) => {
            if (event.type === "blur")
              return;

            let newRow = params.row;
            newRow.playlists = newValue;

            // Update videos on the datagrid.
            let idx = videos.findIndex(e => e.id === params.id);
            let videosAux = [];
            Object.assign(videosAux, videos);
            videosAux[idx].playlists = newValue;
            setVideos(videosAux);

            // Update videos to edit.
            idx = videosToEdit.findIndex(e => e.id === params.id);
            if (idx == -1)
              setVideosToEdit([...videosToEdit, newRow]);
            else {
              videosToEdit[idx] = newRow;
              setVideosToEdit(videosToEdit);
            }
          }}
          renderInput={(autocompleteParams) => (<TextField {...autocompleteParams} label="" variant="outlined" />)}
        />
      ),
    }
  ];

  return (
    <Grid item xs={10} sm={10} md={12} ml={2} mr={2}>
      <Typography variant="h5" component="h2" mb={1} color="black">Vídeos (Youtube)</Typography>
      <Grid container direction="row" justifyContent="end" alignItems="center" mb={1}>
        <Grid item mr={1}>
          <LoadingButton variant="outlined" size="small" loading={updateVideosLoading} onClick={() => updateVideos()}>
            Atualizar
          </LoadingButton>
        </Grid>
        <Grid item mr={1}>
          <LoadingButton variant="outlined" size="small" loading={deleteVideosLoading} onClick={() => deleteVideos()}>
            Deletar
          </LoadingButton>
        </Grid>
      </Grid>
      <DataGrid
        getRowHeight={() => 'auto'}
        apiRef={apiRef}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={listLoading}
        rows={videos}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
          setSelectedVideos(ids);
        }}
        processRowUpdate={(newRow, oldRow) => {
          const idx = videosToEdit.findIndex(e => e.id === oldRow.id);
          if (idx == -1)
            setVideosToEdit([...videosToEdit, newRow]);
          else {
            videosToEdit[idx] = newRow;
            setVideosToEdit(videosToEdit);
          }
            
          setVideos(videos.map((row) => row.id === oldRow.id ? newRow : row));
          return newRow;
        }}
      />
    </Grid>
  );
}