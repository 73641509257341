import { BASE_URL } from "../../settings";

export function getAnalytics(user) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		}
	};

	return fetch(BASE_URL+"/api/analytics", requestOptions);
}