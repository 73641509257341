import { useContext } from "react";
import { AppContext } from "./AppContext";

export default function AuthRouteGuard({element, role}) {
  let context = useContext(AppContext);

  if (!context.auth.isLogged() || context.auth.role !== role) {
    let url = window.location.origin;
    return window.location.replace(url);
  }

  return element;
}