export class User {
  constructor(userData=undefined) {
    var result = document.cookie.match(new RegExp("auth" + '=([^;]+)'));
    
    if (result && !userData) {
      let data = JSON.parse(result[1]);
      this.id = data.id;
      this.name = data.name;
      this.email = data.email;
      this.role = data.role;
      this.token = data.token;
      this.tokenExpiration = data.tokenExpiration;
    }
    else if (userData) {
      this.id = userData.id;
      this.name = userData.name;
      this.email = userData.email;
      this.role = userData.role;
      this.token = userData.token;
      this.tokenExpiration = userData.tokenExpiration;
    }
  }

  isLogged() {
    return typeof this.id !== "undefined";
  }

  isAdmin() {
    return this.role === "Admin";
  }

  logout() {
    this.id = undefined;
    this.name = undefined;
    this.email = undefined;
    this.role = undefined;
    this.token = undefined;
    this.tokenExpiration = undefined;
  }
}