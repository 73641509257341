import { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { AppContext } from '../../shared/AppContext';
import { getFrontPagePlaylists, getPlaylistsNames, saveFrontPagePlaylists } from '../APIService';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function tabMenuProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PlaylistForm({weekDay, playlists, selectedPlaylist, setSelectedPlaylist}) {
  return (
    <Grid container direction="row" spacing={1} justifyContent="center">
      {selectedPlaylist.map((_, playlistIdx) => {
        return (
          <Grid item md="auto" key={"playlist-form-"+weekDay+"-"+playlistIdx}>
            <Autocomplete
              size="small"
              style={{"width": "25rem"}}
              freeSolo
              autoSelect
              options={playlists}
              value={selectedPlaylist[playlistIdx]}
              onChange={(event, newValue) => {
                let aux = Object.assign([], selectedPlaylist);
                aux[playlistIdx] = newValue;
                setSelectedPlaylist(aux);
              }}
              renderInput={(params) => (<TextField {...params} variant="outlined" label={(playlistIdx+1)+"˚ playlist"}/>)}
            />
          </Grid>
        )
      })}
    </Grid>
  );
}

export default function FrontPagePlaylists() {
  let context = useContext(AppContext);
  const numberOfTopPlaylists = 10;
  const [tab, setTab] = useState(0);
  const [playlists, setPlaylists] = useState([]);
  const [latestFrontPagePlaylists, setLatestFrontPagePlaylists] = useState([]);
  const [selectedPlaylist0, setSelectedPlaylist0] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist1, setSelectedPlaylist1] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist2, setSelectedPlaylist2] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist3, setSelectedPlaylist3] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist4, setSelectedPlaylist4] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist5, setSelectedPlaylist5] = useState(Array(numberOfTopPlaylists).fill(""));
  const [selectedPlaylist6, setSelectedPlaylist6] = useState(Array(numberOfTopPlaylists).fill(""));

  function savePlaylists() {
    const payload = [
      selectedPlaylist0,
      selectedPlaylist1,
      selectedPlaylist2,
      selectedPlaylist3,
      selectedPlaylist4,
      selectedPlaylist5,
      selectedPlaylist6];

    saveFrontPagePlaylists(context.auth, payload)
      .then(async promise => {
        let status = promise.status;
        
        if (status === 201) {
          toast.success("Playlists da página inicial salvas.");
        }
        else {
          toast.error("Playlists da página inicial não puderam ser salvas.");
        }
      });
  }

  useEffect(() => {
    getPlaylistsNames(context.auth)
      .then(async promise => {
        let status = promise.status;

        if (status !== 200) {
          toast.error("Falha ao carregar nomes de playlists.");
        }
        else {
          let data = await promise.json();
          setPlaylists(data);
        }
      });

      getFrontPagePlaylists(context.auth)
        .then(async promise => {
          let status = promise.status;

          if (status !== 200) {
            toast.error("Falha ao carregar playlists da página inicial.");
          }
          else {
            let data = await promise.json();
            data.playlists.forEach((d, i) => {
              if (i === 0)
                setSelectedPlaylist0(d);
              if (i === 1)
                setSelectedPlaylist1(d)
              if (i === 2)
                setSelectedPlaylist2(d)
              if (i === 3)
                setSelectedPlaylist3(d)
              if (i === 4)
                setSelectedPlaylist4(d)
              if (i === 5)
                setSelectedPlaylist5(d)
              if (i === 6)
                setSelectedPlaylist6(d)
            });
          }
        });
  }, []);

  const handleChange = (event, newValue) => { setTab(newValue); };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Segunda" {...tabMenuProps(0)} />
          <Tab label="Terça" {...tabMenuProps(1)} />
          <Tab label="Quarta" {...tabMenuProps(2)} />
          <Tab label="Quinta" {...tabMenuProps(3)} />
          <Tab label="Sexta" {...tabMenuProps(4)} />
          <Tab label="Sábado" {...tabMenuProps(5)} />
          <Tab label="Domingo" {...tabMenuProps(6)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={tab} index={0}>
        <PlaylistForm weekDay={0} playlists={playlists} selectedPlaylist={selectedPlaylist0} setSelectedPlaylist={setSelectedPlaylist0} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <PlaylistForm weekDay={1} playlists={playlists} selectedPlaylist={selectedPlaylist1} setSelectedPlaylist={setSelectedPlaylist1}  />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={2}>
        <PlaylistForm weekDay={2} playlists={playlists} selectedPlaylist={selectedPlaylist2} setSelectedPlaylist={setSelectedPlaylist2} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={3}>
        <PlaylistForm weekDay={3} playlists={playlists} selectedPlaylist={selectedPlaylist3} setSelectedPlaylist={setSelectedPlaylist3} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={4}>
        <PlaylistForm weekDay={4} playlists={playlists} selectedPlaylist={selectedPlaylist4} setSelectedPlaylist={setSelectedPlaylist4} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={5}>
        <PlaylistForm weekDay={5} playlists={playlists} selectedPlaylist={selectedPlaylist5} setSelectedPlaylist={setSelectedPlaylist5} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={6}>
        <PlaylistForm weekDay={6} playlists={playlists} selectedPlaylist={selectedPlaylist6} setSelectedPlaylist={setSelectedPlaylist6} />
      </CustomTabPanel>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item md="auto" mr={2}>
          <LoadingButton variant="outlined" size="small" onClick={() => savePlaylists()}>Salvar</LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}