import './Admin.css';

import Grid from '@mui/material/Grid';

import AddYoutubeVideo from './components/AddYoutubeVideo';
import ListYoutubeVideos from './components/ListYoutubeVideos';
import UserList from './components/UserList';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FrontPagePlaylists from './components/FrontPagePlaylists';
import Analytics from './analytics/Analytics';

export default function Admin() {
  document.title = "Gospelflix | Admin";

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <ToastContainer autoClose={2000} />
      <Grid item xs={12} sm={11} md={10}>
        <Grid container mt={3} direction="row" className="admin-container">
          <AddYoutubeVideo />
        </Grid>
        <Grid container mt={3} direction="row" className="admin-container">
          <Analytics />
        </Grid>
        <Grid container mt={3} direction="row" className="admin-container">
          <ListYoutubeVideos />
        </Grid>
        <Grid container mt={3} direction="row" className="admin-container">
          <FrontPagePlaylists />
        </Grid>
        <Grid container mt={3} direction="row" className="admin-container">
          <UserList />
        </Grid>
      </Grid>
    </Grid>
  );
}