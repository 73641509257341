import { BASE_URL } from "../settings";

export function getUsers(user) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		}
	};

	return fetch(BASE_URL+"/api/auth/users", requestOptions);
}

export function getPlaylistsNames(user) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		}
	};

	return fetch(BASE_URL+"/api/playlistsnames", requestOptions);
}

export default function createVideo(user, videoId, title, playlists) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		},
		body: JSON.stringify({
			youtubeVideoId: videoId,
			title: title,
			playlists: playlists
		})
	};

	return fetch(BASE_URL+"/api/video", requestOptions);
}

export function getAllVideos(user) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		}
	};

	return fetch(BASE_URL+"/api/videos", requestOptions);
}

export function updateVideos(user, payload) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		},
		body: JSON.stringify(payload)
	};

	return fetch(BASE_URL+"/api/videos", requestOptions);
}

export function deleteVideos(user, payload) {
	const requestOptions = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		},
		body: JSON.stringify(payload)
	};

	return fetch(BASE_URL+"/api/videos", requestOptions);
}

export function saveFrontPagePlaylists(user, playlists) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		},
		body: JSON.stringify({
			playlists: playlists
		})
	};

	return fetch(BASE_URL+"/api/frontpageplaylists", requestOptions);
}

export function getFrontPagePlaylists(user) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': user.token
		}
	};

	return fetch(BASE_URL+"/api/frontpageplaylists", requestOptions);
}