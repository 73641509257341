import { BASE_URL } from "../settings";

export function getVideoPlaylists() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	};

	return fetch(BASE_URL+"/api/frontpageplaylistsvideos", requestOptions);
}
