import { useContext, useEffect, useState } from "react";

import CustomNoRowsOverlay from '../../../shared/CustomNoRowsOverlay';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';

export default function Pages({data, loading}) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    let pagesDict = {};
    data.forEach(d => {
      if (d.pageTitle in pagesDict) {
        pagesDict[d.pageTitle].accessCount += 1;
        if (pagesDict[d.pageTitle].latestAccess < d.createdAt)
          pagesDict[d.pageTitle].latestAccess = d.createdAt;
      }
      else {
        pagesDict[d.pageTitle] = {
          "accessCount": 1,
          "latestAccess": d.createdAt
        };
      }
    });
    
    let pagesList = [];
    for (const [key, value] of Object.entries(pagesDict)) {
      pagesList.push({
        "id": key,
        "accessCount": value.accessCount,
        "latestAccess": value.latestAccess
      });
    }

    pagesList.sort(function(a, b) { return b.accessCount - a.accessCount; });

    setPages(pagesList);
  }, [data]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 550 },
    { field: 'accessCount', headerName: 'Número de acessos', width: 250 },
    { field: 'latestAccess', headerName: 'Último acesso', width: 250 },
  ];

  return (
    <Grid item xs={10} sm={10} md={12} mt={6} ml={2}>
      <Typography variant="h6" component="h2" mb={1} color="black">Páginas</Typography>
      <DataGrid
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={pages}
        columns={columns}
        pageSize={5}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </Grid>
  );
}