import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner'

export default function HomeLoader({visible}) {
  const [msgIdx, setMsgIdx] = useState(0);
  const messages = [
    "Selecionando o melhor conteúdo para você...",
    "Carregando playlists...",
    "Carregando vídeos..."
  ];
  
  function iterateMessageIndex(idx) {
    if (idx > messages.length - 1)
      return;

    setMsgIdx(idx);

    setTimeout(() => { iterateMessageIndex(idx + 1); }, 3000);
  }

  useEffect(() => {
    iterateMessageIndex(0);
  }, []);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Typography variant="h5" component="h2" mb={4} color="#ffffffe6" fontSize="1.3rem">{messages[msgIdx]}</Typography>
      <ThreeCircles
        visible={visible}
        height="100"
        width="100"
        color="#ffffffe6"
        ariaLabel="three-circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Grid>
  );
}