import { BASE_URL } from "../settings";

export default function sendAnalytics(pageTitle, userAgent, userLanguage, deviceId) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			pageTitle: pageTitle,
			userAgent: userAgent,
			userLanguage: userLanguage,
			deviceId: deviceId
		})
	};

	return fetch(BASE_URL+"/api/analytics", requestOptions);
}