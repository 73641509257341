import "./HomeAppBar.css";

import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";

import { Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { AppContext } from "./AppContext";

export default function HomeAppBar() {
  let context = React.useContext(AppContext);
  let navigate = useNavigate();

  function logout() {
    document.cookie="auth="
    context.auth.logout();
    navigate("");
  }

  return (
  <nav className="navbar navbar-expand-lg home-app-bar">
    <Button onClick={() => navigate("")} className="appbar-title">
      <span className="appbar-logo">
        Gospelflix
      </span>
    </Button>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <MenuIcon className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
      <div className="navbar-nav">
        {/* {context.auth.isLogged() && <Link to="admin" className="link-no-decoration custom-menu-item" color="inherit">Vídeos salvos</Link>} */}
        <a className="link-no-decoration custom-menu-item" href="mailto:contato@gospelflix.tv?subject=Enviar conteúdo&body=Olá, gostaria de saber como ter meus vídeos listados no Gospelflix.">Envie seu vídeo</a>
        <Link to="sobre" className="link-no-decoration custom-menu-item" color="inherit">Sobre</Link>
        {context.auth.isLogged() && context.auth.isAdmin() && <Link to="admin" className="link-no-decoration custom-menu-item" color="inherit">Área administrativa</Link>}

        {context.auth.isLogged() ? 
          <Link to="/" className="link-no-decoration custom-menu-item" color="inherit" onClick={() => { logout(); return false; }}>Sair</Link> :
          <Link to="login" className="link-no-decoration custom-menu-item" color="inherit">Entrar</Link>
        }
      </div>
    </div>
  </nav>
  );
}