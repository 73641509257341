import Grid from '@mui/material/Grid';

import { useContext, useEffect, useState } from "react";
import { getAnalytics } from "./APIService";
import { AppContext } from "../../shared/AppContext";
import { toast } from "react-toastify";
import Devices from './components/Devices';
import Pages from './components/Pages';

export default function Analytics() {
  let context = useContext(AppContext);
  const [analytics, setAnalytics] = useState([]);
  const [listLoading, setListLoading] = useState(false);

  useEffect(() => {
    getAnalytics(context.auth)
      .then(async promise => {
        let status = promise.status;

        if (status !== 200) {
          toast.error("Falha ao carregar nomes de playlists.");
        }
        else {
          let data = await promise.json();
          console.log(data)
          setAnalytics(data);
        }
      });
  }, []);

  return (
    <Grid container direction="row">
      <Devices data={analytics} loading={listLoading} />
      <Pages data={analytics} loading={listLoading} />
    </Grid>
  );
}