import { useSearchParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLocation } from "react-router-dom";
import { useContext, useEffect } from 'react';
import getVideoInfo from './APIService';
import sendAnalytics from '../shared/APIService';

import { AppContext } from '../shared/AppContext';

function Video() {
  const [searchParams] = useSearchParams();
  let context = useContext(AppContext);

  let youtubeUrl = "https://www.youtube.com/embed/" + searchParams.get("id");

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getVideoInfo(searchParams.get("id"))
      .then(async promise => {
				let status = promise.status;

        if (status === 200) {
          let data = await promise.json();
          document.title = "Gospelflix | "+data.title;

          sendAnalytics(document.title, navigator.userAgent, navigator.language, context.deviceId);
				}
        else {
          navigate("/");
        }
			});
  }, []);

  const shareVideo = () => {
    if (navigator.share) {
      navigator
        .share({
            title: document.title,
            text: "",
            url: window.location.href
        })
        .catch(err => console.error(err));
    }
    else {
      navigator.clipboard.writeText(window.location.href);
      toast.success("Link copiado.");
    }
  }

  const saveVideo = () => {
    toast.success("Vídeo salvo.");
  }

  function renderMenu() {
    return (
      <Grid item md={12} justifyContent="left">
        {location.key !== "default" && <Button onClick={() => navigate(-1)} style={{"color": "white"}}><ArrowBackIosNewOutlinedIcon /> voltar</Button>}
        {/* <Button onClick={() => saveVideo()} style={{"color": "white"}}><BookmarkAddOutlinedIcon /> salvar</Button> */}
        <Button onClick={() => shareVideo()} style={{"color": "white"}}><Diversity3OutlinedIcon /> &nbsp;enviar para um amigo</Button>
      </Grid>
    );
  }

  return (
    <Grid container>
      <ToastContainer autoClose={2000} />
      {renderMenu()}
      <Grid item xs={12} md={12} mt={0.5} style={{height: "80vh"}} justifyContent="center">
        <iframe 
          width="100%"
          height="100%"
          src={youtubeUrl}
          title="YouTube video player"
          allow="accelerometer; encrypted-media; gyroscope;"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default Video;
