import { BASE_URL } from "../settings";

export default function registerUser(name, email, password) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			name: name,
			email: email,
			password: password
		})
	};

	return fetch(BASE_URL+"/api/auth/signup", requestOptions);
}

export function loginUser(email, password) {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email: email,
			password: password
		})
	};

	return fetch(BASE_URL+"/api/auth/signin", requestOptions);
}