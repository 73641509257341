import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import { LinearProgress } from '@mui/material';

import CustomNoRowsOverlay from '../../shared/CustomNoRowsOverlay';

import { toast } from 'react-toastify';

import { useContext, useEffect, useState } from 'react';
import { getUsers } from '../APIService';
import { AppContext } from '../../shared/AppContext';

const columns = [
  { field: 'id', headerName: 'ID', width: 250 },
  { field: 'name', headerName: 'Nome', width: 250, editable: true },
  { field: 'email', headerName: 'Email', width: 250 },
	{ field: 'role', headerName: 'Permissão', width: 150 }
];

export default function UserList() {
  let context = useContext(AppContext);
  const [listLoading, setListLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setListLoading(true);
    getUsers(context.auth)
      .then(async promise => {
        let status = promise.status;
        let data = await promise.json();

        if (status !== 200) {
          toast.error("Falha ao carregar usuários.");
        }
        else {
          setUsers(data);
        }
      })
      .finally(() => setListLoading(false));
  }, []);

  return (
    <Grid item xs={10} sm={10} md={12} ml={2} mr={2}>
      <Typography variant="h5" component="h2" mb={1} color="black">Usuários</Typography>
      <DataGrid
					slots={{
						loadingOverlay: LinearProgress,
						noRowsOverlay: CustomNoRowsOverlay,
					}}
					loading={listLoading}
					rows={users}
					columns={columns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					checkboxSelection
					onRowSelectionModelChange={(ids) => {
						setSelectedUsers(ids);
					}}
          onCellEditStart={params => {
            const { id, field, value } = params;
            setUsers(users.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            ));
          }}
				/>
    </Grid>
  );
}