import './App.css';

import { useState } from 'react';

import { Routes, Route } from "react-router-dom";

import { AppContext } from './shared/AppContext';
import AuthRouteGuard from './shared/AuthRouteGuard';
import { User } from './login/User';

import Home from './home/Home';
import Video from './video/Video';
import Login from './login/Login';
import Admin from './admin/Admin';
import HomeAppBar from './shared/HomeAppBar';
import About from './about/About';

import { v4 as uuidv4 } from 'uuid';

const ADMIN = "Admin"
const GENERAL = "General"

function App() {
	const [auth, setAuth] = useState(new User());

	// Set or get device id.
	var deviceCookie = document.cookie.match(new RegExp("deviceId" + '=([^;]+)'));
	let deviceUuid = uuidv4();
	if (!deviceCookie)
		document.cookie = ["deviceId", "=", deviceUuid].join('');
	else
		deviceUuid = deviceCookie[1];

	const [deviceId, setDeviceId] = useState(deviceUuid);

	return (
		<AppContext.Provider value={{
      setAuth: (user) => {setAuth(user)},
      auth: auth,
			setDeviceId: (d) => {setDeviceId(d)},
			deviceId: deviceId
    }}>
			<HomeAppBar />
      <Routes>
				<Route index element={<Home />} />
				<Route path="v" element={<Video />} />
				<Route path="sobre" element={<About />} />
				<Route path="login" element={<Login />} />
				<Route path="admin" element={<AuthRouteGuard element={<Admin />} role={ADMIN} />}/>
      </Routes>
		</AppContext.Provider>
	);
}

export default App;
